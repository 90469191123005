<template>
  <v-card
    class="highlight-required-fields"
  >
    <v-card-title>
      Quick Notes
      <v-spacer></v-spacer>
      <!-- clear search/filters -->
      <btn
        label="Reset Search/Filters"
        color="secondary"
        :icon="icons.mdiUndoVariant"
        @click="resetSearchFilters"
      ></btn>
    </v-card-title>

    <v-expand-transition>
      <div
        v-show="!isFiltersSet"
        style="height: 60px"
      >
        <alert class="mx-5">
          Facility and Visit Date must be selected to create a communication log.
        </alert>
      </div>
    </v-expand-transition>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="usersQuickEntry"
      item-key="id"
      :expanded.sync="expanded"
      :page.sync="isFiltersSet ? 1 : pagination.page"
      :items-per-page="isFiltersSet ? -1 : pagination.itemsPerPage"
      :sort-by.sync="sort.by"
      :sort-desc.sync="sort.desc"
      hide-default-footer
      show-select
      show-expand
      single-expand
      class="has-pagination"
      @click:row="viewQuickEntry"
      @current-items="getFiltered"
      @page-count="pagination.pageCount = $event"
    >
      <template #top>
        <v-row class="mx-4 mt-2 pb-1">
          <!-- first name -->
          <text-field
            v-model="search.first_name"
            :append-icon="icons.mdiMagnify"
            label="First Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- last name -->
          <text-field
            v-model="search.last_name"
            :append-icon="icons.mdiMagnify"
            label="Last Name"
            class="col-sm-4 px-1"
          ></text-field>
          <!-- date of birth -->
          <date-input
            v-model="search.dob_us"
            :append-icon="icons.mdiMagnify"
            label="Date of Birth"
            class="col-sm-4 px-1"
            rules="dob"
          ></date-input>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- facility filter -->
          <select-box
            v-model="search.facility"
            :items="facilities"
            label="Facility"
            class="col-sm-6 px-1"
            :class="!search.facility && 'required-field'"
          ></select-box>
          <!-- gender filter -->
          <select-box
            v-model="search.gender"
            :items="genders"
            label="Gender"
            class="col-sm-3 px-1"
          ></select-box>
          <!-- last 4 of ssn -->
          <text-field
            v-model="search.ssn_last_4"
            :append-icon="icons.mdiMagnify"
            label="SSN"
            mask="####"
            placeholder="####"
            class="col-sm-3 px-1"
          ></text-field>
        </v-row>

        <v-row class="mx-4 mt-0 pb-1">
          <!-- visit date last 14 days -->
          <checkbox
            v-model="search.last_fourteen_days"
            class="col-sm-1 px-0"
            label="Last 14"
            hide-details
            stack
            @click="if (search.last_fourteen_days) search.visit_date = null"
          ></checkbox>
          <!-- visit date -->
          <date-picker
            v-model="search.visit_date"
            label="Visit Date"
            class="col-sm-3p25 px-1"
            :class="!search.visit_date && 'required-field'"
            clearable
            @input="if (search.visit_date) search.last_fourteen_days = false"
          ></date-picker>
          <!-- visit type filter -->
          <select-box
            v-model="search.visit_type"
            :items="visitTypes"
            label="Type of Visit"
            class="col-sm-3 px-1"
          ></select-box>
          <!-- log status -->
          <select-box
            v-model="search.logStatus"
            :items="logStatus"
            label="Log Status"
            class="col-sm-2p5 px-1"
          ></select-box>
          <!-- synced? -->
          <select-box
            v-model="search.synced"
            :items="query"
            label="Synced"
            class="col-sm-2p25 px-1"
          ></select-box>
        </v-row>

        <!-- icon help -->
        <v-expand-transition>
          <v-row
            v-show="showHelp"
            class="help-icons"
          >
            <div class="secondary--text">
              Icon Help:
            </div>
            <icon-value
              :icon="icons.mdiChevronDown"
              color="secondary"
            >
              Expand
            </icon-value>
            <icon-value
              :icon="icons.mdiDecagramOutline"
              color="secondary"
            >
              Validated
            </icon-value>
            <icon-value
              :icon="icons.mdiEmailOutline"
              color="secondary"
            >
              Log Status
            </icon-value>
            <icon-value
              :icon="icons.mdiCloudOutline"
              color="secondary"
            >
              Synced
            </icon-value>
            <icon-value
              :icon="icons.mdiDotsVertical"
              color="secondary"
            >
              Action Menu
            </icon-value>
            <v-icon @click="showHelp = !showHelp">
              {{ icons.mdiClose }}
            </v-icon>
          </v-row>
        </v-expand-transition>
      </template>

      <!-- header overrides -->
      <template #header.data-table-select>
        <v-checkbox
          v-model="selectAllStatus"
          :indeterminate="selectAllIndeterminate"
          hide-details
          color="secondary"
          :disabled="!isFiltersSet || !filtered.length"
          @click="selectAllToggle"
        ></v-checkbox>
      </template>
      <template #header.is_quick_entry_validated>
        <v-icon>{{ icons.mdiDecagramOutline }}</v-icon>
      </template>
      <template #header.communication_log.log_status>
        <v-icon>{{ icons.mdiEmailOutline }}</v-icon>
      </template>
      <template #header.is_synced>
        <v-icon>{{ icons.mdiCloudOutline }}</v-icon>
      </template>
      <template #header.actions>
        <v-icon
          color="warning"
          @click="showHelp = !showHelp"
        >
          {{ icons.mdiInformationOutline }}
        </v-icon>
      </template>

      <!-- table data overrides -->
      <template #item.data-table-select="{ item }">
        <v-checkbox
          v-model="selected"
          :value="item"
          hide-details
          color="secondary"
          :disabled="!isFiltersSet
            || !item.is_quick_entry_validated
            || (item.communication_log
              && (item.communication_log.log_status === 'Sent' || item.communication_log.log_status === 'Initiated')
            )"
          @click.stop="updateSelectStatus"
        ></v-checkbox>
      </template>

      <!-- first name -->
      <template #item.first_name="{ item }">
        <div class="table-no-wrap">
          {{ item.first_name }}
        </div>
      </template>

      <!-- last name -->
      <template #item.last_name="{ item }">
        <div class="table-no-wrap">
          {{ item.last_name }}
        </div>
      </template>

      <!-- visit date -->
      <template #item.visit_date="{ item }">
        {{ $date(item.visit_date).format('MM/DD/YYYY') }}
      </template>

      <!-- facility -->
      <template #item.place_of_service_id="{ item }">
        <div class="table-no-wrap">
          {{ $store.getters['facilities/getById'](item.place_of_service_id).title }}
        </div>
      </template>

      <!-- visit type -->
      <template #item.visit_type="{ item }">
        <span
          class="visit-type"
          :class="item.visit_type.toLowerCase()"
        >
          {{ item.visit_type === 'Follow-up' ? 'F/U' : item.visit_type }}
        </span>
      </template>

      <!-- Validated -->
      <template #item.is_quick_entry_validated="{ item }">
        <v-icon
          :color="item.is_quick_entry_validated || item.is_signed ? 'success' : 'error'"
        >
          {{ item.is_quick_entry_validated || item.is_signed ? icons.mdiCheckDecagram : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- Log status -->
      <template #item.communication_log.log_status="{ item }">
        <v-icon
          :color="logStatusColor(item.communication_log)"
        >
          {{ logStatusIcon(item.communication_log) }}
        </v-icon>
      </template>

      <!-- Synced -->
      <template #item.is_synced="{ item }">
        <v-icon
          :color="item.is_synced ? 'success' : 'error'"
        >
          {{ item.is_synced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle }}
        </v-icon>
      </template>

      <!-- actions -->
      <template #item.actions="{item}">
        <v-menu
          transition="slide-y-transition"
          offset-y
          left
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ attrs['aria-expanded'] === 'true' ? icons.mdiClose : icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="!!item.communication_log_id"
              @click="viewQuickEntry(item)"
            >
              <icon-value
                :disabled="!!item.communication_log_id"
                :icon="icons.mdiAccountClock"
              >
                View/Edit Quick Entry
              </icon-value>
            </v-list-item>

            <v-list-item
              :disabled="!item.communication_log_id"
              @click="communicationLogDetails(item)"
            >
              <icon-value
                :disabled="!item.communication_log_id"
                :icon="icons.mdiEmailSearch"
              >
                Communication Log Details
              </icon-value>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              :disabled="item.is_deceased"
              @click="createQuickEntry(item)"
            >
              <icon-value
                :disabled="item.is_deceased"
                :icon="icons.mdiClockPlus"
              >
                Create New Quick Entry
              </icon-value>
            </v-list-item>

            <v-list-item @click="editPatient(item)">
              <icon-value :icon="icons.mdiAccountEdit">
                Edit This Patient
              </icon-value>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!-- expanded data -->
      <template #expanded-item="{ item }">
        <!--
          The getPatient() method can be CPU intense, and was being accessed many times
          in the section below. So this for "loop" is a trick to only run the getPatient()
          once per encounter. There's only one item in the array, so it only renders once,
          but it allows the use of the "patient" value to be used without overhead.
        -->
        <td
          v-for="(patient) in [getPatient(item.patient_id)]"
          :key="patient.id"
          :colspan="headers.length"
          class="wounds"
          style="padding: 0 !important"
        >
          <v-card-actions>
            <div class="col-sm-1 text-center">
              Status
            </div>
            <div class="col-sm-3">
              Practice Type
            </div>
            <div class="col-sm-2">
              Last DoS
            </div>
            <div class="col-sm-4">
              Location
            </div>
            <div class="col-sm-2">
              Etiology
            </div>
          </v-card-actions>

          <div
            v-for="(wound, index) in patient.wounds"
            :key="`wound-${index}`"
          >
            <div
              v-if="woundExistsNow(wound, item.visit_date, item.id)"
            >
              <!--
              The latestTreatment() method can be CPU intense, and was being accessed many times
              in the section below. So this "loop" is a trick to only run the latestTreatment()
              once per wound. There's only one item in the array, so it only renders once, but
              it allows the use of the "treatment" value to be used without additional overhead.
            -->
              <div
                v-for="(treatment) in [latestTreatment(wound, item.visit_date, item.id)]"
                :key="treatment.id"
                @click="viewQuickEntry(item)"
              >
                <v-card-actions>
                  <!-- current treatment - validated -->
                  <v-icon
                    v-if="isNewTreatment(treatment, item.id) && treatmentIsValidated(treatment)"
                    large
                    color="success"
                    class="col-sm-1"
                  >
                    {{ icons.mdiCheckDecagram }}
                  </v-icon>

                  <!-- current treatment - NOT validated -->
                  <v-icon
                    v-if="isNewTreatment(treatment, item.id) && !treatmentIsValidated(treatment)"
                    large
                    color="error"
                    class="col-sm-1"
                  >
                    {{ icons.mdiAlertDecagram }}
                  </v-icon>

                  <!-- previous treatment -->
                  <v-icon
                    v-if="!isNewTreatment(treatment, item.id)"
                    large
                    color="warning"
                    class="col-sm-1"
                  >
                    {{ icons.mdiClipboardAlert }}
                  </v-icon>

                  <div
                    class="col-sm-3 practice-type-title"
                  >
                    #{{ wound.wound_number }} -
                    {{ $store.getters['baseData/practiceTypeFromId'](wound.practice_type_id).title }}
                    <v-icon
                      v-if="wound.practice_type_id === 1 && treatment.wound_treatment && treatment.wound_treatment.infection_signs.some(x => x.title !== 'None')"
                      large
                      color="error"
                      class="ml-3"
                    >
                      {{ icons.mdiBiohazard }}
                    </v-icon>
                  </div>

                  <div :class="`col-sm-2 ${isNewTreatment(treatment, item.id) ? '' : 'warning--text'}`">
                    {{ formattedStatus(treatment) }}
                  </div>

                  <div class="col-sm-4">
                    <span v-if="wound.practice_type_id !== 5">
                      {{ formattedLocation(wound) }}
                    </span>
                  </div>

                  <div class="col-sm-2">
                    <span v-if="wound.practice_type_id !== 5">
                      {{ formattedEtiology(treatment) }}
                    </span>
                  </div>
                </v-card-actions>
              </div>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-expand-transition>
      <div
        v-show="!isFiltersSet"
        style="height: 78px"
      >
        <pagination
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :page-count="pagination.pageCount"
        ></pagination>
      </div>
    </v-expand-transition>

    <div
      v-if="isFiltersSet"
      class="pb-5"
    ></div>

    <v-card-actions>
      <btn
        v-if="isFiltersSet"
        label="Create Communication Log"
        :icon="icons.mdiEmailArrowRight"
        :disabled="!selected.length"
        offline-disable
        color="success"
        @click="openCommModal"
      ></btn>
      <v-spacer></v-spacer>
      <btn
        label="Create Quick Entry"
        :icon="icons.mdiAccountClock"
        @click="$router.push({ name: 'quick-entry-patient-find' })"
      ></btn>
    </v-card-actions>

    <!-- log detail modal -->
    <v-dialog
      v-model="detailModalState"
      width="550"
      persistent
    >
      <v-card>
        <v-card-title class="pb-0">
          Communication Log Details
        </v-card-title>

        <NoteSection>
          <text-field
            :value="logDetails.log_status"
            label="Communication Log Status"
            class="col-sm-6 mt-3"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="$date(logDetails.created).format('MM/DD/YYYY hh:mm A')"
            label="Sent On"
            class="col-sm-6 mt-3"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="$store.getters['facilities/getById'](logDetails.place_of_service_id).title"
            label="Facility"
            class="col-sm-8"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="$date(logDetails.visit_date).format('MM/DD/YYYY')"
            label="Visit Date"
            class="col-sm-4"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-field
            :value="logDetails.password"
            label="Password for Communication Log"
            class="col-sm-12"
            readonly
            @focus="$event.target.blur()"
          ></text-field>
          <text-area
            :value="logDetails.contact_email || ' '"
            :noLength="true"
            label="Email Recipients"
            class="col-sm-12"
            readonly
          ></text-area>
        </NoteSection>

        <v-card-actions>
          <!-- cancel -->
          <btn
            label="Close"
            color="secondary"
            :icon="icons.mdiClose"
            @click="detailModalState = false"
          ></btn>
          <v-spacer></v-spacer>
          <btn
            label="Resend Log"
            color="error"
            :icon="icons.mdiEmailArrowRight"
            @click="resendCommunicationLog"
          ></btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- create communication log modal -->
    <v-dialog
      v-model="commModalState"
      width="650"
      persistent
    >
      <v-card>
        <v-form v-model="formValid">
          <v-card-title class="pb-0">
            Create Communication Log

            <v-spacer></v-spacer>

            <!-- reset -->
            <btn
              label="Reset Values"
              color="secondary"
              :icon="icons.mdiUndoVariant"
              @click="openCommModal"
            ></btn>
          </v-card-title>

          <NoteSection>
            <text-field
              :value="facility.title"
              label="Facility"
              class="col-sm-8 mt-3"
              disabled
            ></text-field>
            <text-field
              :value="$date(search.visit_date).format('MM/DD/YYYY')"
              label="Visit Date"
              class="col-sm-4 mt-3"
              disabled
            ></text-field>
            <text-area
              :value="communicationLog.patients"
              :noLength="true"
              label="Patients Included in Communication Log"
              class="col-sm-12"
              rows="1"
              readonly
              no-click
            ></text-area>
            <text-field
              v-model="communicationLog.password"
              label="Password for Communication Log"
              class="col-sm-7"
              readonly
              password
              :password-visible.sync="communicationLog.isPasswordVisible"
              @focus="$event.target.blur()"
            ></text-field>
            <btn
              label="Generate New Password"
              color="warning"
              class="col-sm-5"
              :icon="icons.mdiLockReset"
              @click="generateNewPassword"
            ></btn>
            <v-combobox
              v-model="communicationLog.recipients"
              :items="communicationLog.emailOptions"
              :rules="computedRules"
              :delimiters="[',', ' ']"
              label="Email Recipients"
              counter="10"
              hide-selected
              outlined
              multiple
              chips
              deletable-chips
              @change="recipientsChanged"
            ></v-combobox>
          </NoteSection>

          <alert class="mx-5">
            <p>
              Are you sure you wish to create the communication log and email it to the listed recipients at {{ facility.title }}?
            </p>
            Warning: This operation cannot be undone!
          </alert>

          <v-card-actions>
            <!-- cancel -->
            <btn
              label="Cancel"
              color="secondary"
              :icon="icons.mdiCancel"
              @click="commModalState = false; communicationLog.isPasswordVisible = false"
            ></btn>

            <v-spacer></v-spacer>

            <!-- send -->
            <btn
              label="Create Log"
              color="error"
              :icon="icons.mdiEmailArrowRight"
              :disabled="!formValid"
              offline-disable
              @click="createCommunicationLog"
            ></btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable no-param-reassign */

import '@/components/patients/primitives'
import SyncEncounters from '@/mixins/SyncEncounters'
import {
  mdiAccountClock, mdiAccountEdit,
  mdiAccountMultiple,
  mdiAlertDecagram,
  mdiBiohazard,
  mdiCancel,
  mdiCheckDecagram,
  mdiChevronDown,
  mdiClipboardAlert,
  mdiClockPlus,
  mdiClose,
  mdiCloseCircle,
  mdiCloudCheckVariant, mdiCloudOutline,
  mdiDecagramOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiEmailArrowRight, mdiEmailCheck,
  mdiEmailOutline,
  mdiEmailSearch,
  mdiInformationOutline,
  mdiLockReset,
  mdiMagnify, mdiUndoVariant,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import dayjs from 'dayjs'
import compact from 'lodash/compact'
import { mapGetters, mapState } from 'vuex'

const initialSearch = {
  last_fourteen_days: false,
  visit_date: null,
  visit_date_us: null,
  facility: null,
  visit_type: null,
  validated: null,
  logStatus: null,
  synced: null,
  ssn_last_4: null,
  first_name: null,
  last_name: null,
  gender: null,
  dob: null,
  dob_us: null,
}

const initialState = {
  by: 'last_name',
  desc: false,
}

export default {
  mixins: [SyncEncounters], // Methods: syncEncounters(), syncCorePatients(), syncFullPatients(), syncingProgress()
  data() {
    return {
      formValid: false,
      detailModalState: false,
      commModalState: false,
      communicationLog: {
        password: null,
        emailOptions: [],
        recipients: [],
        patients: null,
        isPasswordVisible: false,
      },
      logDetails: {},
      logDetailEncounterId: null,
      search: {
        ...initialSearch,
        visit_date: this.$date().format('YYYY-MM-DD'),
      },
      filtered: [],
      selected: [],
      selectAllStatus: false,
      selectAllIndeterminate: false,
      sort: { ...initialState },
      userId: this.$authUser.userId(),
      icons: {
        mdiCloseCircle,
        mdiMagnify,
        mdiUndoVariant,
        mdiCheckDecagram,
        mdiEmailArrowRight,
        mdiEmailCheck,
        mdiEmailAlert,
        mdiCancel,
        mdiClose,
        mdiDotsVertical,
        mdiAccountClock,
        mdiAccountEdit,
        mdiEmailSearch,
        mdiClockPlus,
        mdiEmailOutline,
        mdiDecagramOutline,
        mdiInformationOutline,
        mdiChevronDown,
        mdiCloudCheckVariant,
        mdiCloudOutline,
        mdiBiohazard,
        mdiAlertDecagram,
        mdiClipboardAlert,
        mdiAccountMultiple,
        mdiLockReset,
      },
      showHelp: false,
      headers: [
        {
          text: 'First',
          value: 'first_name',
          filter: value => {
            const valueToCheck = value || '' // default to an empty string if value is falsy.
            return !this.search.first_name || valueToCheck.toLowerCase().indexOf(this.search.first_name.trim().toLowerCase()) === 0
          },
        },
        {
          text: 'Last',
          value: 'last_name',
          filter: value => {
            const valueToCheck = value || '' // default to an empty string if value is falsy.
            return !this.search.last_name || valueToCheck.toLowerCase().indexOf(this.search.last_name.trim().toLowerCase()) === 0
          },
        },
        {
          text: 'Visit Date',
          value: 'visit_date',
          filter: value => (!this.search.visit_date && !this.search.last_fourteen_days)
            || (!this.search.last_fourteen_days && value === this.search.visit_date)
            || (this.search.last_fourteen_days && dayjs().diff(value, 'days') <= 14),
        },
        {
          text: 'Facility',
          value: 'place_of_service_id',
          align: 'facility',
          filter: value => !this.search.facility || value === this.search.facility,
        },
        {
          text: 'Type',
          value: 'visit_type',
          align: 'xsmall',
          filter: value => !this.search.visit_type || value === this.search.visit_type,
        },
        {
          text: 'Validated',
          value: 'is_quick_entry_validated',
          align: 'xsmall',
        },
        {
          text: 'Status',
          value: 'communication_log.log_status',
          align: 'xsmall',
          filter: value => !this.search.logStatus
            || value === this.search.logStatus
            || (this.search.logStatus === 'Unsent' && !value),
        },
        {
          text: 'Synced',
          value: 'is_synced',
          align: 'xsmall',
          filter: value => this.search.synced === null || value === this.search.synced,
        },
        {
          value: 'dob',
          align: 'hide',
          filter: value => !this.search.dob || value === this.search.dob || this.search.dob.length < 10,
        },
        {
          value: 'gender',
          align: 'hide',
          filter: value => !this.search.gender || value === this.search.gender,
        },
        {
          value: 'ssn_last_4',
          align: 'hide',
          filter: value => !this.search.ssn_last_4 || value === this.search.ssn_last_4 || this.search.ssn_last_4.length < 4,
        },
        {
          text: '', value: 'actions', align: 'xsmall', sortable: false,
        },
      ],
      expanded: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      debounceTimer: null,
    }
  },
  computed: {
    ...mapGetters('baseData', ['query']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('encounters', ['isSynced', 'genders', 'visitTypes', 'logStatus']),
    ...mapState('encounters', { encounters: 'items' }),
    ...mapState('patients', { patients: 'items' }),
    usersQuickEntry() {
      const filteredEncounters = this.encounters.filter(
        x => x.created_by_user_id === this.userId
          && x.deleted !== true
          && x.is_quick_entry === true,
      )
      filteredEncounters
        .filter(x => !x.last_name)
        .forEach(encounter => {
          const patient = this.getPatient(encounter.patient_id)
          encounter.first_name = patient.first_name
          encounter.last_name = patient.last_name
          encounter.gender = patient.gender
          encounter.ssn_last_4 = patient.ssn_last_4
          encounter.dob = patient.dob
          encounter.is_deceased = patient.is_deceased
        })

      return filteredEncounters
    },
    isFiltersSet() {
      return !!this.search.facility && !!this.search.visit_date
    },
    facility() {
      return this.$store.getters['facilities/getById'](this.search.facility)
    },
    computedRules() {
      let rulesArray = []
      rulesArray = rulesArray.concat(this.$validationRules.requiredEmail, this.$validationRules.maxEmails(5))

      return rulesArray
    },
  },
  watch: {
    'search.facility': {
      handler() {
        this.selectAllStatus = false
        this.selectAllIndeterminate = false
        this.selected = []
      },
    },
    'search.visit_date': {
      handler() {
        this.search.visit_date_us = this.search.visit_date !== null ? this.$date(this.search.visit_date).format('MM/DD/YYYY') : null
        this.selectAllStatus = false
        this.selectAllIndeterminate = false
        this.selected = []
      },
    },
    'search.dob_us': {
      handler() {
        this.search.dob = this.search.dob_us ? this.$date(this.search.dob_us).format('YYYY-MM-DD') : null
      },
    },
    search: {
      deep: true,
      handler() { this.updateQuery() },
    },
    pagination: {
      deep: true,
      handler() { this.updateQuery() },
    },
    sort: {
      deep: true,
      handler() { this.updateQuery() },
    },
  },
  mounted() {
    // Query string sets search filters & pagination
    if (!this.$custom.isObjectEmpty(this.$route.query)) {
      this.search = {
        ...initialSearch,
        ...this.$route.query,
        facility: Number(this.$route.query.facility),
        signed: this.$custom.strToBoolNull(this.$route.query.signed),
        synced: this.$custom.strToBoolNull(this.$route.query.synced),
        last_fourteen_days: this.$custom.strToBoolNull(this.$route.query.last_fourteen_days),
      }
      this.sort.by = this.$route.query.by
      this.sort.desc = this.$custom.strToBoolNull(this.$route.query.desc)
      if (this.$route.query.itemsPerPage) {
        this.pagination.itemsPerPage = Number(this.$route.query.itemsPerPage)
      }
      if (this.$route.query.page) {
        this.$nextTick(() => {
          this.pagination.page = Number(this.$route.query.page)
        })
      }
    }
  },
  methods: {
    treatmentIsValidated(treatment) {
      return treatment.is_validated || treatment.is_quick_entry_validated
    },
    woundExistsNow(wound, visitDate, encounterId) {
      /*
        If there's no previous or current treatments, but there's
        treatments in the future, this wound didn't exist at this time
      */
      return (this.latestTreatment(wound, visitDate, encounterId)
        || wound.treatments.findIndex(x => !x.deleted && x.encounter.visit_date > visitDate) === -1
      )
    },
    latestTreatment(wound, visitDate, encounterId) {
      if (!wound.treatments.length) return false

      const treatment = wound.treatments.find(x => x.encounter_id === encounterId && !x.deleted)
      if (treatment) return treatment

      const treatments = wound.treatments.filter(x => !x.deleted && x.encounter.visit_date < visitDate).sort((a, b) => (a.encounter.visit_date < b.encounter.visit_date && 1) || -1)

      return (Array.isArray(treatments) && treatments.length) ? treatments[0] : false
    },
    isNewTreatment(treatment, encounterId) {
      return (!treatment || !treatment.encounter) ? false : encounterId === treatment.encounter_id
    },
    formattedStatus(treatment) {
      return treatment && treatment.encounter ? this.$date(treatment.encounter.visit_date).format('M/D/YYYY') : ''
    },
    formattedLocation(wound) {
      return wound.location ? wound.location.location_text : ''
    },
    formattedEtiology(treatment) {
      if (!treatment) return ''

      const { etiology } = treatment

      return etiology === 'Other' ? `Other - ${treatment.etiology_other}` : etiology
    },
    getFiltered(event) {
      this.filtered = event
    },
    selectAllToggle() {
      if (this.selectAllStatus && !this.selectAllIndeterminate) {
        this.selected = this.filtered.filter(
          x => x.is_quick_entry_validated
          && (
            !x.communication_log
            || (x.communication_log.log_status !== 'Initiated' && x.communication_log.log_status !== 'Sent')
          ),
        )
      } else {
        this.selected = []
      }
      this.$nextTick(() => {
        this.updateSelectStatus()
      })
    },
    updateSelectStatus() {
      this.selectAllIndeterminate = !!this.selected.length && !!this.filtered.length && this.selected.length !== this.filtered.length
      this.selectAllStatus = !!this.selected.length && !this.selectAllIndeterminate
    },
    updateQuery() {
      // Debounce
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        // Replace the query
        const query = { ...this.search, ...this.sort, ...this.pagination }
        Object.keys(query).forEach(key => { if (query[key] === null || typeof query[key] === 'undefined') query[key] = '' })
        window.history.replaceState({}, null, `${this.$route.path}?${new URLSearchParams(query).toString()}`)
        this.$store.commit('route/updateQuery', query)
      }, 250)
    },
    resetSearchFilters() {
      this.search = {
        ...initialSearch,
        visit_date: this.$date().format('YYYY-MM-DD'),
      }
      this.sort = { ...initialState }
      this.showHelp = false
    },
    viewQuickEntry(item) {
      if (item.is_signed && item.is_synced) {
        // View signed surgical note
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](item.practice_type_id).noteRoute,
          query: { id: item.id, quick: true },
        })
      } else if (item.created_by_user_id !== this.userId) {
        // Don't allow a different provider to edit an encounter
        this.$store.dispatch('notify', { value: 'You can\'t edit this encounter as you\'re not the provider who created it.', color: 'error' })
      } else {
        // Edit encounter note
        const logSent = item.communication_log && item.communication_log.log_status === 'Sent'
        if (logSent) {
          this.communicationLogDetails(item)

          /* This alternative shows a confirmation saying that the quick entry has already been sent and allow the user to go to the List Encounters page
          this.$root.confirm({
            titleIconColor: 'warning',
            title: 'Quick Entry Complete',
            body: 'This quick entry has already been sent in a communication log. To edit the encounter, please visit the List Encounters page.',
            cancel: 'List Encounters',
            cancelIcon: this.icons.mdiAccountMultiple,
            cancelColor: 'success',
          }).then(result => {
            if (result === false) {
              this.$router.push({ name: 'list-encounters' })
            }
          })
          */
        } else {
          this.$store.dispatch('loading', true)
          this.$router.push({
            name: this.$store.getters['baseData/practiceTypeFromId'](item.practice_type_id).encounterRoute,
            query: { id: item.id, quick: true },
          })
        }
      }
    },
    openCommModal() {
      this.communicationLog.isPasswordVisible = false
      this.communicationLog.password = this.facility.password
      this.communicationLog.emailOptions = this.$store.getters['auth/getQuickEntryEmailsById'](this.facility?.id)?.email_addresses?.split(',')
      this.communicationLog.emailOptions?.forEach((email, index) => {
        this.communicationLog.emailOptions[index] = email.trim()
      })
      this.communicationLog.recipients = this.communicationLog.emailOptions

      const patients = []
      this.selected.forEach(patient => {
        patients.push(`${patient.first_name} ${patient.last_name}`)
      })
      this.communicationLog.patients = patients.join(', ')

      this.commModalState = true
    },
    recipientsChanged() {
      let validEmail = true
      this.communicationLog.recipients.forEach((recipient, index) => {
        if (!/.+@.+\..+/.test(recipient)) {
          this.communicationLog.recipients.splice(index, 1)
          validEmail = false
        }
      })
      if (!validEmail) {
        this.$store.dispatch('notify', { value: 'Invalid email address entered.', color: 'error' })
      }
    },
    createQuickEntry(item) {
      this.$router.push({ name: 'create-encounter', query: { id: item.patient_id, quickEntry: true }, params: { practiceTypeId: item.practice_type_id } })
    },
    editPatient(item) {
      this.$router.push({ name: 'edit-patient', query: { id: item.patient_id } })
    },
    communicationLogDetails(item) {
      this.logDetails = item.communication_log
      this.logDetailEncounterId = item.id
      this.detailModalState = true
    },
    resendCommunicationLog() {
      this.detailModalState = false

      const encountersToSync = []
      const patientsToSync = []
      const encounterIds = [this.logDetailEncounterId]

      encountersToSync.push(this.$store.getters['encounters/getById'](this.logDetailEncounterId))
      patientsToSync.push(this.$store.getters['patients/getById'](encountersToSync[0].patient_id))

      // Create communication log object
      const commLog = {
        created_by_user_id: this.$authUser.userId(),
        place_of_service_id: this.logDetails.place_of_service_id,
        visit_date: this.logDetails.visit_date,
        log_type: 'Update',
        password: this.logDetails.password,
        contact_email: this.logDetails.contact_email,
        encounter_ids: encounterIds,
      }
      this.syncLogPrep(encountersToSync, patientsToSync, commLog)

      // This alternative up/down syncs everything, which I don't think is required to resend a communication log
      // this.sendCommunicationLog(encountersToSync, patientsToSync, commLog)

      // Start the sync process
      this.syncPos = 1
      this.syncCnt = 2
      this.syncingProgress()
      this.$store.dispatch('encounters/syncingModal', true)
      this.$store.dispatch('encounters/sendCommLog', {
        ...commLog,
      }).then(commLogSuccess => {
        if (commLogSuccess) {
          this.$store.dispatch('notify', { value: 'Communication log resent.' })
        } else {
          this.errorDialog(commLogSuccess, 'Failed to send communication log!')
        }
        this.logCleanup()
      })
    },
    createCommunicationLog() {
      this.commModalState = false

      // Filter the selected encounter array to be sure we're sending expected data
      const encountersToSync = this.selected.filter(encounter => (
        encounter.is_quick_entry
        && encounter.is_quick_entry_validated
        && encounter.created_by_user_id === this.$authUser.userId()
        && encounter.place_of_service_id === this.search.facility
        && encounter.visit_date === this.search.visit_date
      ))

      // Build patients to sync
      let patientsToSync = []
      let encounterIds = []
      encountersToSync.forEach(encounter => {
        delete encounter.can_be_deleted
        patientsToSync.push(this.$store.getters['patients/getById'](encounter.patient_id))
        encounterIds.push(encounter.id)
      })

      // De-dupe arrays
      patientsToSync = [...new Set(compact(patientsToSync))]
      encounterIds = [...new Set(compact(encounterIds))]

      // Create communication log object
      const commLog = {
        created_by_user_id: this.$authUser.userId(),
        place_of_service_id: this.search.facility,
        visit_date: this.search.visit_date,
        log_type: 'Initial',
        password: this.communicationLog.password,
        contact_email: this.communicationLog.recipients.join(', '),
        encounter_ids: encounterIds,
      }

      this.sendCommunicationLog(encountersToSync, patientsToSync, commLog)
    },
    generateNewPassword() {
      this.communicationLog.isPasswordVisible = false
      this.$root.confirm({
        title: 'Generate New Communication Log Password?',
        html: true,
        body: `Are you sure you wish to generate a new communication log password for <b>${this.facility.title}</b>?<br><br>
               Note: The new password won't be saved till a new communication log is sent and won't work for previous communication logs.<br><br>`,
        confirm: 'Generate New Password',
        confirmColor: 'error',
        confirmIcon: this.icons.mdiLockReset,
      }).then(result => {
        if (result) {
          // Easily ambiguous numbers/letters removed (O and 0, l and 1, etc.) - 500 billion combinations (29^8)
          const validChars = '23456789abcdefghkmnprstuvwxyz'
          let newPassword = ''
          for (let i = 0; i < 8; i += 1) {
            const randomNumber = Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000) * validChars.length)
            newPassword += validChars.substring(randomNumber, randomNumber + 1)
          }
          this.communicationLog.isPasswordVisible = true
          this.communicationLog.password = newPassword
          this.$store.dispatch('notify', { value: 'New communication log password generated.' })
        }
      })
    },
    sendCommunicationLog(encountersToSync, patientsToSync, commLog) {
      this.$store.commit('auth/updateQuickEntryEmails', {
        id: commLog.place_of_service_id, email_addresses: commLog.contact_email,
      })

      // Reset the sync progress values
      this.syncPos = 0
      this.syncCnt = patientsToSync.length * 2 + encountersToSync.length + 2
      this.syncLogSetup(encountersToSync, patientsToSync, commLog)

      // Start the sync process
      this.syncingProgress()
      this.$store.dispatch('encounters/syncingModal', true)
      console.log('---------- Comm Log Starting ----------')
      this.syncCorePatients(patientsToSync).then(patientCoreSuccess => {
        if (patientCoreSuccess === true) {
          this.syncEncounters(encountersToSync).then(encounterSuccess => {
            if (encounterSuccess === true) {
              this.syncFullPatients(patientsToSync).then(patientFullSuccess => {
                if (patientFullSuccess === true) {
                  console.log('Post communication log...')
                  this.$store.dispatch('encounters/sendCommLog', {
                    ...commLog,
                  }).then(commLogSuccess => {
                    if (commLogSuccess === true) {
                      console.log('Done posting communication log...')
                      console.log('Get all encounters...')
                      this.$store.dispatch('syncAll', { pos: this.syncPos, cnt: this.syncCnt }).then(() => {
                        this.syncingProgress()
                        console.log('Done getting patients and encounters')
                        this.$store.dispatch('auth/refresh')
                        console.log('---------- Comm Log Completed Successfully ----------')
                        this.$store.dispatch('notify', { value: 'Sync all data successful.' })
                        this.logCleanup()
                      })
                    } else {
                      this.errorDialog(commLogSuccess, 'Failed to send communication log!')
                      this.logCleanup()
                    }
                  })
                } else {
                  this.errorDialog(patientFullSuccess, 'One or more patients failed to sync!')
                  this.logCleanup()
                }
              })
            } else {
              this.errorDialog(encounterSuccess, 'One or more quick entry failed to sync!')
              this.logCleanup()
            }
          })
        } else {
          this.errorDialog(patientCoreSuccess, 'One or more patients failed to sync!')
          this.logCleanup()
        }
      })
    },
    logCleanup() {
      this.selected = []
      this.$store.dispatch('encounters/syncingModal', false)
      this.updateSelectStatus()
    },
    logStatusColor(communicationLog) {
      if (!communicationLog) return 'error'
      switch (communicationLog.log_status) {
        case 'Initiated': return 'warning'
        case 'Sent': return 'success'
        case 'Failed': return 'error'
        default: return 'error'
      }
    },
    logStatusIcon(communicationLog) {
      if (!communicationLog) return this.icons.mdiCloseCircle
      switch (communicationLog.log_status) {
        case 'Initiated': return this.icons.mdiEmailArrowRight
        case 'Sent': return this.icons.mdiEmailCheck
        case 'Failed': return this.icons.mdiEmailAlert
        default: return this.icons.mdiCloseCircle
      }
    },
    getPatient(patientId) {
      return this.$store.getters['patients/getById'](patientId)
    },
    syncLogPrep(encounters, patients, commLog) {
      if (patients.length > 0 && !patients[0]?.sync_log) {
        patients[0].sync_log = this.syncLogSetup('Communication Log: Patients')
      }
      if (encounters.length > 0 && !encounters[0]?.sync_log) {
        encounters[0].sync_log = this.syncLogSetup('Communication Log: Encounters')
      }
      if (commLog.length > 0 && !commLog[0]?.sync_log) {
        commLog[0].sync_log = this.syncLogSetup('Communication Log: Comm Log')
      }
    },
  },
}
</script>

<style lang="scss">
  .wounds {
    > div {
      margin-bottom: 0 !important;
    }
    .v-card__actions {
      padding: 0 6px !important;
      margin-bottom: 6px !important;
      > * {
        padding: 0;
      }
    }
    >.v-card__actions {
      line-height: 2em;
      background: $component-lighter-border-color;
    }
  }
</style>
